import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

const getDeveloperData = async devId => {
  const response = await graphqlClient.query({
    query: gql`
      query getDeveloperInfo($devId: uuid!) {
        property_developer(where: { id: { _eq: $devId } }) {
          name
          id
          address
          contact_area_code
          contact_email
          contact_phone
          description
          logo
          website
          zone
          deleted
          property_developer_settings {
            id
            value
            special_value
            property_developer_setting_value_id
          }
          projects {
            id
            address
            location
            name
            project_setting_values {
              id
              value
            }
          }
          country {
            id
            name
          }
          accounts{
            user{
              first_name
              last_name
            }
          }
        }
      }
    `,
    variables: {
      devId: devId
    }
  });
  return response.data.property_developer[0];
};

const createDeveloper = async (
  companyName,
  phoneArea,
  phone,
  countryId,
  email
) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation createCompany($developer: property_developer_insert_input!) {
        insert_property_developer_one(object: $developer) {
          id
        }
      }
    `,
    variables: {
      developer: {
        name: companyName,
        contact_area_code: phoneArea,
        contact_phone: phone,
        contact_email: email,
        address: null,
        website: null,
        country_id: countryId
      }
    }
  });

  return response.data.insert_property_developer_one;
};

const editDeveloperProfile = async (devId, field, value) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation editDeveloper(
        $updateData: property_developer_set_input
        $developerID: uuid!
      ) {
        update_property_developer(
          where: { id: { _eq: $developerID } }
          _set: $updateData
        ) {
          returning {
            name
          }
        }
      }
    `,
    variables: {
      developerID: devId,
      updateData: {
        [field]: value
      }
    }
  });
  return response.data.update_property_developer;
};

const editDeveloperSettings = async (
  settingType,
  value,
  svalue,
  developerId
) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updateSettings(
        $setting: property_developer_setting_value_enum!
        $updateSetting: property_developer_setting_set_input!
        $developerId: uuid!
      ) {
        update_property_developer_setting(
          where: {
            property_developer_setting_value_id: { _eq: $setting }
            property_developer_id: { _eq: $developerId }
          }
          _set: $updateSetting
        ) {
          returning {
            id
            special_value
            value
          }
        }
      }
    `,
    variables: {
      setting: settingType,
      developerId: developerId,
      updateSetting: {
        value: value,
        special_value: svalue
      }
    }
  });

  return response;
};

const createCustomColorsValues = async developerID => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addMenuSettings(
        $data: property_developer_setting_insert_input!
      ) {
        insert_property_developer_setting_one(object: $data) {
          id
          property_developer_id
        }
      }
    `,
    variables: {
      data: {
        value: null,
        property_developer_setting_value_id: "MENU_SETTINGS",
        property_developer_id: developerID,
        special_value: {
          backgroundMenu: "#1155CC",
          colorHeaderOption: "#F1C232",
          colorOptionMenu: "#ffffff",
          colorOptionSelected: "#434343",
          backgroundOptionSelected: "#f1c232"
        }
      }
    }
  });

  return response;
};

async function addDeveloperSetting(developerId, settingValue, developerSetting, specialValue) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addDeveloperSetting($setting: [property_developer_setting_insert_input!]!) {
        insert_property_developer_setting(objects: $setting) {
          affected_rows
          returning {
            id
            value
            special_value
            property_developer_setting_value_id
          }
        }
      }
    `,
    variables: {
      setting: {
        value: (settingValue) ? settingValue: null,
        property_developer_id: developerId,
        property_developer_setting_value_id: developerSetting,
        special_value: (specialValue) ? specialValue: null
      }
    }
  });
  return response.data.insert_property_developer_setting;
}

async function updDeveloperSettingById(developerSettingId, settingValue, specialValue) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPropertySetting($setting: property_developer_setting_set_input!) {
        update_property_developer_setting(where: {
          id: {
            _eq: "${developerSettingId}"
          }
        }_set: $setting) {
          affected_rows
          returning {
            id
            property_developer_id
            property_developer_setting_value_id
            value
            special_value
          }
        }
      }
    `,
    variables: {
      setting: {
        value: (settingValue) ? settingValue : null,
        special_value: (specialValue) ? specialValue: null
      }
    }
  });
  return response.data.update_property_developer_setting;
}

const createDeveloperSetting = async (developerId, settingValue, settingType) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addDeveloperSetting(
        $data: property_developer_setting_insert_input!
      ) {
        insert_property_developer_setting_one(object: $data) {
          id
          property_developer_id
        }
      }
    `,
    variables: {
      data: {
        property_developer_id: developerId,
        property_developer_setting_value_id: settingType,
        value: settingValue
      }
    }
  });

  return response.data.insert_property_developer_setting_one;
}

const deleteAccount = async (developerId) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation deleteDeveloper($developerId:uuid!) {
        deleteDeveloper(developer:{
          id: $developerId
        }) {
          id
          deleted
        }
      }
    `,
    variables: {
      developerId
    }
  });
  return response.data.deleteDeveloper;
};

export default {
  getDeveloperData,
  createDeveloper,
  editDeveloperProfile,
  editDeveloperSettings,
  createCustomColorsValues,
  addDeveloperSetting,
  updDeveloperSettingById,
  createDeveloperSetting,
  deleteAccount
};
