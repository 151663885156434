<template>
  <div id="profile-page">
    <!-- Perfil de desarrolladora -->
    <vs-col vs-type="flex" vs-justify="flex-end">
      <vs-row>
        <vs-button
          class="m-1 mb-4"
          color="success"
          type="filled"
          icon-pack="feather"
          icon="icon-plus"
          to="/formulario-de-ingreso-de-proyecto"
          >Proyecto</vs-button
        >
      </vs-row>
    </vs-col>

    <div class="vx-row">
      <!-- Proyectos de la desarrolladora-->
      <div class="flex flex-wrap sm:full lg:w-full mt-4">
        <div
          class="w-full sm:w-1/2 md:w-3/3 lg:w-3/1 xl:w-1/3 p-2"
          v-for="project in this.projects"
          v-bind:key="project.id"
        >
          <vx-card>
            <div slot="no-body">
              <img
                :src="showFirstImageGallery(project.project_images)"
                alt="content-img"
                class="responsive card-img-top"
              />
            </div>
            <h5 class="mb-2 font-normal text-2xl">{{ project.name }}</h5>
            <p class="text-black font-normal text-base">
              {{ project.description }}
            </p>
            <div class="flex justify-between flex-wrap">
              <vs-button
                class="mt-4 shadow-lg w-full"
                @click="openListProperties(project)"
                to="seleccion-de-nivel/"
                >Ver Proyecto</vs-button
              >
            </div>
          </vx-card>
        </div>
      </div>
      <!-- OVERLAY CARD -->
      <div
        class="vx-col w-full lg:w-1/3 sm:w-2/1 mt-8"
        v-if="
          dataDeveloper.logo ==
            'https://flattlo-app.s3.amazonaws.com/base-stock-images/sinPLanoAdjunto.jpg'
        "
      >
        <vx-card class="overflow-hidden cardDefault">
          <template slot="no-body">
            <div class="floatChip"></div>
            <img
              src="@/assets/images/profile/user-uploads/CompleteProfile2.jpg"
              alt="user-profile-cover"
              class="responsive"
            />
            <div class="card-overlay text-dark flex flex-col justify-between">
              <h4 class="text-dark marginDefault text-center">
                1. Completa tu perfil empresarial.
              </h4>
              <div class="flex justify-around">
                <vs-button
                  icon-pack="feather"
                  :to="{ name: 'Perfil Empresarial' }"
                  icon="icon-globe"
                  color="primary"
                  class="btnAddProyect mt-1"
                  >Completar perfil</vs-button
                >
              </div>
              <br />
            </div>
          </template>
        </vx-card>
      </div>

      <div
        class="vx-col w-full lg:w-1/3 sm:w-2/1 mt-8"
        v-if="projects.length == 0"
      >
        <vx-card class="overlay-card overflow-hidden cardDefault">
          <template slot="no-body">
            <div class="floatChip"></div>
            <img
              src="@/assets/images/profile/user-uploads/UploadProyect2.jpg"
              alt="user-profile-cover"
              class="responsive"
            />
            <div class="card-overlay text-black flex flex-col justify-between">
              <h4 class="text-black marginDefault text-center">
                2. Crea un proyecto inmobiliario.
              </h4>
              <div class="flex justify-around">
                <vs-button
                  icon-pack="feather"
                  :to="{ name: 'Ingresar Proyecto' }"
                  icon="icon-plus"
                  color="primary"
                  class="btnAddProyect mt-1"
                  >Crear Proyecto</vs-button
                >
              </div>
              <br />
            </div>
          </template>
        </vx-card>
      </div>

      <!-- OVERLAY CARD -->
      <div
        class="vx-col w-full lg:w-1/3 sm:w-2/1 mt-8"
        v-if="dataDeveloper.accounts.length < 2"
      >
        <vx-card class="overlay-card overflow-hidden cardDefault">
          <template slot="no-body">
            <div class="floatChip"></div>
            <img
              src="@/assets/images/profile/user-uploads/AddSalesTeam2.jpg"
              alt="user-profile-cover"
              class="responsive"
            />
            <div class="card-overlay text-dark flex flex-col justify-between">
              <h4 class="text-dark marginDefault text-center">
                3. Ingresa a tu equipo<br />
                de ventas.
              </h4>
              <div class="flex justify-around">
                <vs-button
                  icon-pack="feather"
                  :to="{ name: 'Equipo Comercial' }"
                  icon="icon-user-plus"
                  color="primary"
                  class="btnAddProyect mt-1"
                  >Agregar Vendedores</vs-button
                >
              </div>
              <br />
            </div>
          </template>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../../projects/services";
import { mapState } from "vuex";
import { developerApi } from "../services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      projects: [],
      dataDeveloper: [],
      isNavOpen: false,
      userPoll: "",
      salesTeamDataReady: false,
      adminMail: "",
      company: "",
      dataPhone: null,
      dataWeb: null,
      dataProfileImage: null,
      mediaExtensions: {
        img: ["jpg", "jpeg", "png", "bmp", "gif", "exif", "tiff"],
        video: ["avi", "flv", "wmv", "mov", "mp4", "3gp"]
      },

      userCoverImg: "cover.jpg",
      projectoImg: "projecto.jpg",
      projectoImg2: "projecto2.jpg"
    };
  },
  methods: {
    showFirstImageGallery(images) {
      const coverImages = images.filter(
        item => item.project_image_gallery === "FINISHES"
      );
      if (!coverImages.length) {
        return "https://flattlo-app.s3.amazonaws.com/base-stock-images/sinPLanoAdjunto.jpg";
      }

      return coverImages[0].img_url;
    },
    openListProperties(project) {
      const projectId = project.id;
      const currencySymbol = project.country_currency
        ? project.country_currency.currency.symbol
        : null;

      this.$store.dispatch("project/currentProject", { projectId });
      this.$store.dispatch("project/currencySymbol", currencySymbol);
    },
    getMenuSettings() {
      let settingColors = this.dataDeveloper.property_developer_settings.filter(
        n => n.property_developer_setting_value_id == "MENU_SETTINGS"
      );

      this.setSideBarColors(settingColors);
    },
    setSideBarColors(v) {
      if (v[0] == undefined || null) {
        const payload = {
          backgroundMenu: "#1155CC",
          colorHeaderOption: "#F1C232",
          colorOptionMenu: "#ffffff",
          colorOptionSelected: "#434343",
          backgroundOptionSelected: "#f1c232",
          defaultColors: false
        };
        this.$store.commit("SET_SETTING_MENU", payload);
      } else {
        const payload = {
          backgroundMenu: v[0].special_value.backgroundMenu,
          colorHeaderOption: v[0].special_value.colorHeaderOption,
          colorOptionMenu: v[0].special_value.colorOptionMenu,
          colorOptionSelected: v[0].special_value.colorOptionSelected,
          backgroundOptionSelected: v[0].special_value.backgroundOptionSelected,
          defaultColors: true
        };
        this.$store.commit("SET_SETTING_MENU", payload);
      }
    }
  },

  async mounted() {
    try {
      const user = this.$store.getters["auth/getUser"];
      const developerId = user.developerId;
      const userProjects = user.userProject ? user.userProject : null;
      this.projects = await api.listProjects(developerId);

      if (userProjects.length) {
        const projectIds = userProjects.map(i => i.project_id);
        const projectAssigned = this.projects.filter(item => {
          return projectIds.includes(item.id);
        });
        this.projects = projectAssigned;
      }

      this.dataDeveloper = await developerApi.getDeveloperData(developerId);
      this.getMenuSettings();
      this.$store.state.project.breadcrumbs = [
        { pageName: 'Proyectos disponibles', route: '/panel-desarrolladora' },
      ];
    } catch (e) {
      console.log(e);
      sentryCaptureException(e, "quoter");
    }
  },
  computed: {
    ...mapState(["developerData"])
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";

.floatChip {
  position: absolute;
  margin: 1em;
  font-size: 18px;
  font-weight: 600;
}

[dir] .vx-card .vx-card__collapsible-content .card-overlay::after {
  margin: auto;
  background: none;
}

//BtnEditDashboard
.btnEditDashboard {
  position: absolute;
  margin-top: -25.33333333%;
  margin-left: 80.33333333%;
  animation: pulse 2s infinite;
}

//PulseAnimation
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(3, 22, 128, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(3, 22, 128, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(3, 22, 128, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 22, 128, 0.4);
    box-shadow: 0 0 0 0 rgba(3, 22, 128, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(3, 22, 128, 0);
    box-shadow: 0 0 0 10px rgba(3, 22, 128, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(3, 22, 128, 0);
    box-shadow: 0 0 0 0 rgba(3, 22, 128, 0);
  }
}

//Navbar slider Yello
.vs-sidebar {
  z-index: 90000;
}

.cardDefault {
  height: 498px;
}
.marginDefault {
  margin-top: 1em;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 8.8em;
}
.btnAddProyect {
  margin: 0 auto;
  width: 70%;
  margin-top: -30px;
  height: 3em;
  border-radius: 23em;
  -webkit-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  -moz-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
}
.btnAddProyect:hover {
  background-color: #43a047 !important;
}
.HugeNumber {
  font-size: 72px;
  display: block;
  margin: 0 auto;
  margin-top: 36px;
  margin-bottom: -230px;
}

/*ScrollBar*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bbdefb;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #64b5f6;
}

::-webkit-scrollbar-thumb {
  background-color: #0d47a1;
  border-radius: 0px;
}

.mobile_menu_bar:before {
  color: #64b5f6;
}

#et-info-email:before,
#et-info-phone:before,
#et_search_icon:before,
.comment-reply-link:after,
.et-cart-info span:before,
.et-pb-arrow-next:before,
.et-pb-arrow-prev:before,
.et-social-icon a:before,
.et_audio_container .mejs-playpause-button button:before,
.et_audio_container .mejs-volume-button button:before,
.et_overlay:before,
.et_password_protected_form .et_submit_button:after,
.et_pb_button:after,
.et_pb_contact_reset:after,
.et_pb_contact_submit:after,
.et_pb_font_icon:before,
.et_pb_newsletter_button:after,
.et_pb_pricing_table_button:after,
.et_pb_promo_button:after,
.et_pb_social_icon a.icon:before,
.et_pb_testimonial:before,
.et_pb_toggle_title:before,
.form-submit .et_pb_button:after,
.mobile_menu_bar:before,
.woocommerce #content input.button.alt:after,
.woocommerce #content input.button:after,
.woocommerce #respond input#submit.alt:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button.alt:after,
.woocommerce button.button.alt:after,
.woocommerce button.button:after,
.woocommerce button.single_add_to_cart_button.button:after,
.woocommerce input.button.alt:after,
.woocommerce input.button:after,
.woocommerce-page #content input.button.alt:after,
.woocommerce-page #content input.button:after,
.woocommerce-page #respond input#submit.alt:after,
.woocommerce-page #respond input#submit:after,
.woocommerce-page a.button.alt:after,
.woocommerce-page a.button:after,
.woocommerce-page button.button.alt:after,
.woocommerce-page button.button:after,
.woocommerce-page input.button.alt:after,
.woocommerce-page input.button:after,
a.et_pb_more_button:after {
  font-weight: 900;
}

#app {
  background-color: rgb(247, 247, 247);
}

@media only screen and (min-width: 320px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -35.33333333%;
    margin-left: 75.33333333%;
  }
}

@media only screen and (min-width: 375px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -30.33333333%;
    margin-left: 75.33333333%;
  }
}

@media only screen and (min-width: 425px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -25.33333333%;
    margin-left: 80.33333333%;
  }
}

@media only screen and (min-width: 768px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -14.33333333%;
    margin-left: 85.33333333%;
  }
}

@media only screen and (min-width: 1024px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -34.33333333%;
    margin-left: 76.33333333%;
  }
}

@media only screen and (min-width: 1044px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -26.33333333%;
    margin-left: 78.33333333%;
  }
}

@media only screen and (min-width: 1366px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -30.33333333%;
    margin-left: 78.33333333%;
  }
}

@media only screen and (min-width: 1536px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -25.33333333%;
    margin-left: 78.33333333%;
  }
}

@media only screen and (min-width: 2560px) {
  //BtnEditDashboard
  .btnEditDashboard {
    position: absolute;
    margin-top: -13.33333333%;
    margin-left: 85.33333333%;
  }
}
</style>